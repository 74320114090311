import React, { useEffect, useState, useRef, useContext } from 'react'
// import { AppBar, CardMedia } from '@mui/material';
// import Grid from '@mui/material/Grid';
// import Box from '@mui/material/Box';
// import MenuItem from '@mui/material/MenuItem';
// import Select from '@mui/material/Select';
// import InputLabel from '@mui/material/InputLabel';
// import IconButton from '@mui/material/IconButton';
// import CloseIcon from '@mui/icons-material/Close';
// // import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
// import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Nav from './Nav';
import { AppBar, BottomNavigation, BottomNavigationAction, Box, Button, Link, List, ListItem, ListItemText, Paper, Stack, SwipeableDrawer, Tooltip, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import MenuContent from './MenuContent';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import AlbumIcon from '@mui/icons-material/Album';
import EggIcon from '@mui/icons-material/Egg';
import GradingIcon from '@mui/icons-material/Grading';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import AppleIcon from '@mui/icons-material/Apple';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import YouTubeIcon from '@mui/icons-material/YouTube';

// import Switch from '@mui/material/Switch';
import { BatteryCharging90, BatteryChargingFull, MusicNote, Wifi, Wifi2Bar } from '@mui/icons-material';

import projectsObj from '../Projects.json'
import musicObj from '../Music.json'
import * as vars from '../utils/Vars'

import '../../src/browser.css'
import Three from './Three';
import DevToolsWindow from './DevToolsWindow';
import ResumeComponent from './ResumeComponent';

import { MagnifierContext } from '../context/MagnifierProvider';
import { AppContext } from '../context/AppProvider';
import MagView from './MagView';
import AudioPlayerToggle from './audioPlayerToggle';
import Player from './audioPlayer'
import ContactContent from './ContactContent'
import FullViewComponent from './FullViewComponent';
import BrowserContent from './BrowserContent';
import musicIcon from '../assets/music-solid.svg'
import useWindowDimensions from "../hooks/windowDimensions";

const Home = (props) => {
    const setView = props.setView
    const { height, width } = useWindowDimensions();
    const { magnifiedArea, setMagnifiedArea, updateMagnifiedArea, imageSrc, setImageSrc, isViewAble, setIsViewAble } = useContext(MagnifierContext)
    const { isAudioOpen, setIsAudioOpen, navValue, setNavValue, menuState, setMenuState, isFullView, infoOpen, setInfoOpen } = useContext(AppContext);
    const anchor = 'bottom'
    // const[ menuState, setMenuState ] = useState(false)
    // const[ navValue , setNavValue ] = useState(false)
    const [termComand, setTermComand] = useState()
    const [currentItem, setCurrentItem] = useState()
    // const [ infoOpen, setInfoOpen ]  = useState(false)
    const [blink, setBlink] = useState(false)
    const [isContact, setIsContact] = useState(null)
    const [parentDim, setParentDim] = useState()
    const [linkClick, setLinkClick] = useState(false)
    const parentRef = useRef(null);
    const childrenRef = useRef(null);

    const scrollString = 'Digital Illustration,Graphic/Web Design,Full Stack MERN/PERN Web & Application Development ,eCoommerce Development,General Creative / Tech Solutions & More...'

    const scrollSlug = <span style={{ color: '#00FF00' }}>&nbsp; -||- &nbsp;</span>
    const scrollComp = scrollString.split(',').join(`<span style={{color:'#00FF00'}}>&nbsp; -||- &nbsp;</span>`)
    const projects = [] = projectsObj?.projects?.sort((a, b) => b.year - a.year)
    const music = [] = musicObj?.music?.sort((a, b) => b.year - a.year)

    const navViews = ['Design', 'Dev', 'Music', 'Info/Contact']//, 'Contact']
    const navMap = {
        'Design': <DesignServicesIcon />,
        'Dev': <DeveloperModeIcon />,
        'Music': <AlbumIcon />,
        'Resume': <GradingIcon />,
        'Contact': <ContactPageIcon />
    }
    const clicktagStyles = {
        position: 'absolute',
        // transform:'translate(-50%,150%)',
        display: 'flex',
        top: '18%',
        left: '50%',
        transform: 'translate(-50%,0)',
        height: '2.5rem',
        width: '6.5rem',
        padding: '0.25rem',
        backgroundColor: 'rgba(0,0,0,0.7)',
        color: "#fff",
        backdropFilter: 'blur(5px)',
        fontSize: '0.75rem',
        borderRadius: '15px',
        border: '3px solid rgba(0, 0, 0, 1)',
        zIndex: 99999,
        opacity: blink ? '1' : '0',
        visibility: blink ? 'visible' : 'hidden',
        boxShadow: '0px 0px 10px  rgba(0, 255 ,0, 0.4)',
        // whiteSpace:'nowrap'
        textAlign: 'center',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#00FF00',
        gap: '0.25rem'

    }

    var date = new Date();
    var formatter = new Intl.DateTimeFormat([], {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
    });
    var time = formatter.format(date);


    const toggleMenu = () => {
        setMenuState(!menuState)
    }
    const toggleInfo = () => {
        setInfoOpen(!infoOpen)
    }

    // console.log(time); // "10:30 AM"

    // const colors ={
    //     accent_color_1: 'rgba(0,0,0,1)',
    //     accent_color_2: 'rgba(255,255,255,1)',
    //     text_color_1: 'rgba(0,0,0,1)',
    //     box_shadow_01: 'rgba(0,0,0,0.4)',
    //     text_color_2: 'rgba(255,255,255,1)',
    //     term_green: "#00FF00",
    //     closeButton:'#f66969',
    //     term_header: 'rgb(246, 246, 246)',
    //     term_headerText: 'grey',
    //     projectTitle: 'magenta',
    // }
    // const fonts = {
    //     main_app: 'Gilroy',
    //     term_head:'',
    //     term_title:'',
    //     term_text:'Monaco',
    //     term_btn_cls:'futura bold'
    // }
    function sleep(time) {
        return new Promise(resolve => {
            setTimeout(resolve, time)
            // setAnimationFrame instread for event loop purposes
        })
    }
    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        setMenuState(!menuState);
    };

    const handleItemClick = (e) => {
        setCurrentItem(e)

        setLinkClick(true)
        console.log("currentItem", currentItem)
    }
    const arrayChunk = (arr, n) => {
        const array = arr.slice();
        const chunks = [];
        let r = Number(arr.length % n)
        if (r !== 0) {
            for (let i = 0; i < n - r; i++) {
                array.push({ name: 'Filler' })
            }
        }
        while (array.length) chunks.push(array.splice(0, n));

        // console.log("arrayChunk",chunks)
        return chunks;

    };

    const tagFilter = (arr, navIndex) => { return arr?.filter(e => e.tags.includes(vars.navObj[navIndex])) }

    useEffect(() => {
        // console.log('Projects:',projects)
    }, [])

    useEffect(() => {
        // console.log(navValue)
    }, [navValue])
    useEffect(() => {

        if (parentRef.current) {

            let parentHeight = parentRef.current.offsetHeight;
            let parentWidth = parentRef.current.offsetWidth;
            console.log('Parent H | W:', parentHeight, parentWidth)
            setParentDim({
                width: parentWidth,
                height: parentHeight
            })

        }

        if (childrenRef.current) {

            let childrenHeight = childrenRef.current.offsetHeight;
            let childrenWidth = childrenRef.current.offsetWidth;
            console.log('Parent H | W:', childrenHeight, childrenWidth)

        }

    }, [parentRef, childrenRef]);
    function getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    const blinkFunc = async () => {
        let blinkTimes = Math.floor(Math.random() * 1) + 2
        setBlink(false);
        for (let i = 0; i < blinkTimes; i++) {

            setBlink(true);
            await sleep(Math.floor(Math.random() * 1000) + 500)
            setBlink(false);
        }
    }

    const handleContactToggle = () => {
        if (isContact === null) {
            setIsContact(true)
        }
        setIsContact(true)
        setLinkClick(true)
    }
    const handleResumeToggle = () => {
        if (isContact === null) {
            setIsContact(false)
        }
        setIsContact(false)
        setLinkClick(true)
    }
    useEffect(() => {
        const blinkInc = !blink ? 1 : getRandomInt(1, 2);
        if (blink) {
            sleep(1000)
        }
        const intervalId = setInterval(() => {
            blinkFunc()
        }, Math.floor(Math.random() * 1000) + (5000 * blinkInc)); // Random interval between 1 to 6 seconds

        return () => {

            clearInterval(intervalId);
        };
    }, []);
    useEffect(() => {

    }, [isViewAble, magnifiedArea]);

    useEffect(() => {

    }, [isContact]);

    // console.log('scrollString:', scrollComp )

    return (
        <div style={{
            position: 'relative', top: 0, left: 0, width: '100vw',
            maxWidth: '100vw', maxHeight: '100vh', height: '100%',
            overflow: 'hidden',
            boxShadow: 'inset 0px 10px 15px 10px rgba(0,0,0,0.5)'
        }}>
            <AppBar position="sticky"
                sx={{
                    position: 'sticky',
                    // background:"rgba(255,255,255,0.5)",
                    boxShadow: '0px 2px 5px 3px  rgba(0,0,0,0.5)',
                    top: '0px', left: '0px', maxWidth: '100vw', width: '100%', height: '4.75rem',
                    backdropFilter: 'blur(6px)', display: 'flex',
                    flexDirection: ' row', justifyContent: 'center',
                    backgroundColor: 'rgba(0,0,0,0.4)',
                    alignItems: 'center',
                    m: 0, borderBottom: '3px ridge #fff', paddingTop: '1rem',
                    backgroundImage: `url("/assets/BodyBG2.png")`,
                    zIndex: 2,
                    // backgroundSize: "cover",backgroundRepeat:'no-repeat'
                }} >

                <Button onClick={toggleDrawer(anchor, true)} sx={{ position: 'absolute', left: '0rem', top: '0rem', outline: 'none', _focus: 'outline:none' }}
                    _focus='outline:none'
                    className={`hover-shake blink noselect ${!blink ? 'woddle-long' : ''}`}   >
                    <Tooltip title='Portfolio'>

                        <MenuIcon
                            sx={{ fontSize: '2.5rem', color: vars.colors.term_green, outline: 'none', filter: 'drop-shadow 0px 0px 3px rgba(0,255,0,0.7)' }} />
                    </Tooltip>
                </Button>
                <div style={{ margin: 0 }}>

                    <Typography sx={{ margin: 0, fontWeight: 'bold' }}>
                        AJ Phoenix    <span style={{ margin: 0, fontSize: '0.65rem' }}>
                            @ Rex Entropy LLC
                        </span>
                    </Typography>

                    {/* <Typography sx={{margin:0}}>

            Design & Development
            </Typography> */}
                    <div className="bottom-center" style={{ fontSize: '0.8rem' }}>
                        Design
                        <b>
                            <span className="flip-h term-text">
                                {/* <div class="static-block">
                    <hr class="static static1"/>
                    <hr class="static static2"/>
                    <hr class="static static3"/>
                </div>
                <div class="static-block2">
                    <hr class="static static1"/>
                    <hr class="static static2"/>
                    <hr class="static static3"/>
                </div> */}
                                &nbsp;   /  &nbsp;
                                {/* &nbsp;   &  &nbsp; */}
                            </span>
                        </b>
                        {/* <span>&</span>  */}
                        Development
                        <b>

                            <span className="flip-h term-text">
                                {/* <div class="static-block">
                <hr class="static static1"/>
                <hr class="static static2"/>
                <hr class="static static3"/>
            </div>
            <div class="static-block2">
                <hr class="static static1"/>
                <hr class="static static2"/>
                <hr class="static static3"/>
            </div> */}
                                &nbsp;   /  &nbsp;
                                {/* &nbsp;   &  &nbsp; */}
                            </span>
                        </b>
                        {/* <span>&</span>  */}
                        Creative Solutions

                    </div>

                    <div id="scroll-container" style={{
                        paddingTop: '0.25rem', backgroundColor: 'rgba(0, 0, 0, 0.9)',
                        borderBottom: '4px ridge rgba(255,247,123,0.7)', //boxShadow:'inset 0px 0px 5px 3px rgba(255,255,255,0.5)', 
                        boxShadow: `0px -4px 10px 5px rgba(0, 0, 0, 0.5),inset 0px 0px 10px 4px rgba(255,247,123,${blink ? 0.7 : 0.3})`,
                        borderTop: '2px ridge rgba(255,247,123,0.7)', marginTop: '0.25rem', backdropFilter: 'blur(10px)', transition: '2s ease-in-out'
                    }}>
                        <div id={!menuState ? "scroll-text" : ''} style={{ padding: '0', textAlign: 'center' }}>
                            <Typography sx={{ fontSize: '0.75rem', }}// dangerouslySetInnerHTML={{__html:scrollComp}}>
                            >
                                -||- &nbsp;Digital Illustration &nbsp; -||- &nbsp; Graphic/Web Design &nbsp; -||- &nbsp; Full Stack MERN/PERN Web & Application Development  &nbsp; -||- &nbsp; eCoommerce Development &nbsp; -||- &nbsp; General Creative / Tech Solutions & More...
                                {/* <img src='/assets/MedMan_Flat.png' height='20rem' width={'auto'} style={{background:' rgb(255,255,255)',
                background: 'radial-gradient(circle, rgba(255,255,255,0.5) 0%, rgba(255,255,255,0.3) 35%, rgba(255,255,255,0) 81%)'}}/> */}
                            </Typography>
                        </div>
                    </div>
                </div>

            </AppBar>
            {!isAudioOpen &&
                <Tooltip title='Tunes'>
                    <Box sx={{
                        position: 'absolute', zIndex: 5,
                        top: '0.4rem',
                        right: '0.5rem'
                    }}>
                        <AudioPlayerToggle sx={{ position: 'relative' }} />

                    </Box>
                </Tooltip>
            }
            <Box sx={{
                position: 'absolute', //zIndex: isAudioOpen?9999:0,
                top: '4.5rem',
                left: '50%',
                transform: 'translate(-50%,0)',
                maxWidth: '350px', zIndex: 5
            }}>

                {isAudioOpen && <Box sx={{
                    position: 'absolute',
                    top: '-1.5rem',
                    right: '-0.5rem', zIndex: isAudioOpen ? 10 : 0
                }}>
                    <AudioPlayerToggle />

                </Box>}
                <Box sx={{
                    position: 'relative', //zIndex: isAudioOpen?9999:0
                }}>
                    <Player />
                </Box>
            </Box>
            <Container sx={{
                position: 'relative', display: 'flex', flexDirection: 'column', position: 'relative', width: '100%', p: 0,
                fontFamily: 'Gilroy', height: '100vh', flex: 1, margin: 'auto', top: 0, left: 0,
                boxShadow: `0px  0px 5px 2px  ${vars.colors.box_shadow_01}`,
                border: `2px solid ${vars.colors.accent_color_1}`, backgroundImage: `url("/assets/Origins_Expanded_Resize-SML.jpg")`,
                backgroundSize: "contain",

            }} maxWidth='sm' height='100vh' >
                <Link href='https://qaura.pro' target='_blank' >
                    <Tooltip title='QauRa'>
                        <Box component={'img'}
                            className='hover-shake2'
                            onMouseLeave={() => setBlink(false)}
                            src='/assets/QauRaQR_Framed_Brand-Tag.png'
                            sx={{
                                position: 'absolute', top: '3rem', left: '50%', transform: 'translate(-50%,0)',
                                cursor: 'pointer', padding: '0.25rem',
                                // // backgroundSize: "contain",
                                height: 'auto',
                                width: '7em',
                                borderRadius: '10px',
                                boxShadow: '0px 0px 4px 2px rgba(0 ,0 ,0 , 0.5)',
                                zIndex: 3
                            }}>
                        </Box>
                    </Tooltip>
                </Link>
                <Box sx={{ position: 'absolute', left: '50%', transform: 'translate(-50%,0)', display: 'flex', padding: '0.5rem', gap: '0.25rem', margin: 'auto', textDecoration: 'none', zIndex: 3 }}>
                    {/* <Box sx={{position:'absolute',right:'0.5rem',display: 'flex', padding:'0.5rem', gap:'0.25rem', margin: 'auto', textDecoration:'none',zIndex:1000}}> */}
                    <a href='https://instagram.com/ajphnx' target='_blank'>
                        <Tooltip title='Instagram'>
                            <InstagramIcon sx={{ backgroundColor: '#fff', color: '#000', borderRadius: '5px', border: '1px solid #000', cursor: 'pointer', boxShadow: '0px 0px 4px 1px rgba(0 ,0 ,0 , 0.5)' }} className='hover-shake' />
                        </Tooltip>
                    </a>
                    <a href='https://www.facebook.com/AJPHNXGeneralCreator' target='_blank' style={{ cursor: 'pointer' }} className='hover-shake'>
                        <Tooltip title='Facebook'>
                            <FacebookIcon sx={{ backgroundColor: '#fff', color: '#000', borderRadius: '5px', border: '1px solid #000', cursor: 'pointer', boxShadow: '0px 0px 4px 1px rgba(0 ,0 ,0 , 0.5)' }} />
                        </Tooltip>
                    </a>
                    <a href='https://github.com/AJPHNX' target='_blank'>
                        <Tooltip title='Github'>
                            <GitHubIcon sx={{ backgroundColor: '#fff', color: '#000', borderRadius: '5px', border: '1px solid #000', cursor: 'pointer', boxShadow: '0px 0px 4px 1px rgba(0 ,0 ,0 , 0.5)' }} className='hover-shake' />
                        </Tooltip>
                    </a>
                    <a href='https://linkedin.com/in/aj-phoenix' target='_blank'>
                        <Tooltip title='LinkedIn'>

                            <LinkedInIcon sx={{ backgroundColor: '#fff', color: '#000', borderRadius: '5px', border: '1px solid #000', cursor: 'pointer', boxShadow: '0px 0px 4px 1px rgba(0 ,0 ,0 , 0.5)' }} className='hover-shake' />
                        </Tooltip>
                    </a>
                    <a href='https://www.youtube.com/@ajphnx' target='_blank'>
                        <Tooltip title='YouTube'>
                            <YouTubeIcon sx={{ backgroundColor: '#fff', color: '#000', borderRadius: '5px', border: '1px solid #000', cursor: 'pointer', boxShadow: '0px 0px 4px 1px rgba(0 ,0 ,0 , 0.5)' }} className='hover-shake' />
                        </Tooltip>
                    </a>
                </Box>
                <Box sx={{
                    position: 'absolute', bottom: 0, left: 0, overflow: 'hidden', display: 'flex',
                    width: '100%', height: '100%',
                    alignContent: 'center',
                }}>

                    <Box sx={{
                        position: 'relative', overflow: 'hidden', display: 'flex',
                        width: '50%', margin: 'auto', cursor: 'pointer',
                        alignContent: 'center', zIndex: 2
                    }}
                        ref={parentRef}
                        id="portrait"
                        onClick={() => {
                            // setView('xr')
                            window.open('/game', '_blank')
                        }}
                    >
                        <div style={clicktagStyles} >
                            <EggIcon className='blink-2 woddle' sx={{ color: '#00FF00' }} />
                            <p className='typing'>
                                Click Me...
                            </p>
                        </div>
                        {/* {!menuState && */}
                        <Tooltip title='MEaster Egg'>

                            <Box component={'img'}
                                onMouseEnter={() => setBlink(true)}
                                onMouseLeave={() => setBlink(false)}
                                src={!blink ? '/assets/Self-Portrait-Plain-NoGlasses.png' : '/assets/Self-Portrait-Plain-NoGlasses-Closed.png'}
                                sx={{
                                    position: 'relative', top: 0, left: 0,//width:'100%',
                                    // backgroundImage: `url("/assets/Self-Portrait-Plain-NoGlasses.png")`,
                                    // backgroundAttachment:'fixed',
                                    // backgroundSize:'cover',
                                    // // backgroundSize: "contain",
                                    height: '100%',
                                    width: '100%', zIndex: 2
                                }}
                                alt="AJ self-portrait"

                            >
                            </Box>
                        </Tooltip>
                        {/* } */}

                        {/* <div ref={childrenRef} style={{position:'absolute', top: 0}}>
                        <Three  width={parentDim?.width} height={parentDim?.height}/>
                    </div> */}
                        {/* <div style={{position: 'absolute',top:0,left:0,border:'1px solid red', width:'100%',height:'100%'}}>

                </div> */}
                    </Box>
                    <Link href='https://rexentropy.com' target='_blank'>
                        <Tooltip title='RexEntropy.com'>
                            <Box component={'img'}
                                className='hover-shake'
                                onMouseEnter={() => setBlink(true)}
                                onMouseLeave={() => setBlink(false)}
                                src='/assets/Rex_Entropy_Icon_Glow2.png'
                                sx={{
                                    position: 'absolute', bottom: '5rem', right: '1rem',
                                    cursor: 'pointer', padding: '0.25rem',
                                    // // backgroundSize: "contain",
                                    height: '3rem',
                                    width: '3rem',
                                    borderRadius: '50%',
                                    boxShadow: '0px 0px 4px 2px rgba(0 ,0 ,0 , 0.5)'
                                }}>
                            </Box>
                        </Tooltip>
                    </Link>

                    <Link href='https://music.rexentropy.com' target='_blank' >
                        <Tooltip title='The Rex Player'>
                            <Box
                                className='hover-shake'
                                onMouseEnter={() => setBlink(true)}
                                onMouseLeave={() => setBlink(false)}
                                // src={musicIcon}
                                // src='/assets/Parallaxr-Distressed(White BG).png'
                                sx={{
                                    display: 'flex',
                                    position: 'absolute', bottom: '5rem', left: '50%',
                                    translate: 'transform(-50%,0)',
                                    // fontSize:'1.5rem',
                                    cursor: 'pointer', padding: '0.25rem',
                                    // // backgroundSize: "contain",
                                    height: '2.5rem',
                                    width: '2.5rem',
                                    borderRadius: '50%',
                                    // background:'#FFF',
                                    boxShadow: '0px 0px 4px 2px rgba(0 ,0 ,0 , 0.5)'
                                }}>
                                <Box component={'img'}
                                    className='spinner'
                                    src={musicIcon}
                                    sx={{
                                        borderRadius: '50%',
                                        background: '#858585', padding: '0.5rem',
                                        outline: '1px solid #000',
                                        boxShadow: 'inset 0 0 3px rgba( 0,0, 0, 1)',
                                        height: '1.5rem',
                                        width: '1.5rem', margin: 'auto', alignSelf: 'center'
                                    }}>

                                </Box>
                                {/* <MusicNote className='spinner'
                                sx={{margin:'auto',fontSize:'2.5rem',color:'#000'}}/> */}
                                {/* <AlbumIcon className='spinner'
                                sx={{margin:'auto',fontSize:'2.5rem',color:'#000'}}/> */}
                            </Box>
                        </Tooltip>
                    </Link>

                    <Link href='https://parallaxr.shop' target='_blank' >
                        <Tooltip title='Parallaxr.shop'>
                            <Box component={'img'}
                                className='hover-shake'
                                onMouseEnter={() => setBlink(true)}
                                onMouseLeave={() => setBlink(false)}
                                src='/assets/Parallaxr-Distressed(White BG).png'
                                sx={{
                                    position: 'absolute', bottom: '5rem', left: '1rem',
                                    cursor: 'pointer', padding: '0.25rem',
                                    // // backgroundSize: "contain",
                                    height: '2.5rem',
                                    width: '2.5rem',
                                    borderRadius: '50%',
                                    boxShadow: '0px 0px 4px 2px rgba(0 ,0 ,0 , 0.5)'
                                }}>
                            </Box>
                        </Tooltip>
                    </Link>



                    {!menuState &&
                        <Tooltip title='Parallaxr'>
                            <Box component={'img'}
                                src={blink ? '/assets/MedMan_UpFace.png' : '/assets/MedMan_Flat.png'}
                                sx={{ cursor: 'pointer' }}
                                onClick={() => {
                                    setView('xr')
                                }}
                                className='small-image'
                            // sx={{ height:'15%', width:'auto%',}}
                            >
                            </Box>
                        </Tooltip>
                    }

                    {/* <div  className ='path'></div> */}


                </Box>
                {/* <Nav/> */}



                {/* <React.Fragment key={anchor}> */}

                <SwipeableDrawer
                    anchor={anchor}
                    open={menuState}
                    onClose={toggleDrawer(anchor, false)}
                    onOpen={toggleDrawer(anchor, true)}
                    sx={{
                        backgroundColor: 'rgba(255,255,255,0.2)',
                        backdropFilter: 'blur(5px)',
                        borderRadius: '5px', boxShadow: '0px -1px 10px 5px rgba(0, 0, 0, 0.2)',
                        maxWidth: '100vw',
                        maxHeight: '100vh',
                        height: '100%',

                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        zIndex: 10,
                        opacity: menuState ? '1' : '0',
                        display: menuState ? '' : 'none',
                        visibility: menuState ? '' : 'hidden',
                        // border:'1px solid red'
                    }}
                >

                    {/* {navValue === 3 &&
         ( 
         <Paper sx={{position: 'relative',bottom: 0, left: 0, right: 0 ,marginBottom:'3.5rem',minHeight:'fit-content'}}>
              <ResumeComponent toggleMenu={toggleMenu} setNavValue={setNavValue} setCurrentItem={setCurrentItem}/>
          
          </Paper>
         
          )
           } */}

                    {/* {list(anchor)} */}
                    {/* {navValue !== null && (   */}
                    <Box sx={{ backgroundColor: 'rgba(87,86,86,1)' }}>
                        <Paper sx={{
                            display: 'flex', flexDirection: 'column', position: 'relative', bottom: 0, left: 0, right: 0,
                            backgroundColor: 'rgba(255,255,255,1)', minHeight: '345px', maxHeight: '70vh', backdropFilter: 'blur(5px)',
                            alignItems: 'center', justifyContent: 'center',
                            backgroundImage: `url("/assets/BodyBG2.png")`,
                            backgroundSize: "contain",
                        }} elevation={3}

                        >
                            {navValue !== 3 && (<div style={{
                                position: 'absolute',
                                display: 'flex',
                                top: 0,
                                left: 0,
                                width: "100%", height: '1.25rem',
                                backgroundColor: vars.colors.term_header,
                                color: vars.colors.term_headerText,
                                borderRadius: '5px 5px 0px 0px',
                                textAlign: 'center',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                                {/* <div class="term-button-cls" id="term-button-cls"><span class="" id="x-btn-text">x</span></div> */}
                                < AppleIcon sx={{ position: 'absolute', top: '0.125rem', left: '0.25rem', fontSize: '0.75rem' }} />
                                <Typography sx={{ fontSize: '0.65rem', position: 'absolute', left: '1.5rem' }}>
                                    <b>Browser</b>
                                    {/* <span style={{cursor:'pointer'}} onClick={()=>setShowFileMenu(!showFileMenu)}>&nbsp; &nbsp;File  &nbsp; &nbsp;</span> */}
                                </Typography>
                                {/* <Typography sx={{fontSize:'0.85rem'}}>
                    Projects
                </Typography> */}

                                <Typography sx={{
                                    display: 'flex', position: 'absolute',
                                    right: '0.25rem', top: '0rem', fontSize: '0.75rem',
                                    justifyContent: 'center', alignItems: 'center', gap: 1
                                }}>
                                    <BatteryCharging90 sx={{ transform: 'rotate(90deg)', fontSize: '1.25rem' }} />
                                    {/* {!blink 
                        ? <BatteryChargingFull sx={{transform:'rotate(90deg)',fontSize:'1.25rem' }} />
                        :<BatteryCharging90 sx={{transform:'rotate(90deg)',fontSize:'1.25rem' }} />} */}
                                    {blink ? <Wifi fontSize='0.85rem' /> : <Wifi2Bar fontSize='0.85rem' />}
                                    {time}
                                </Typography>
                            </div>)}
                            {navValue !== 3 && (<div className="modal-wrap">
                                <div className="browser-container" id="browser">
                                    <div className="browser-header" id="browser-header">
                                        <div className="browser-button-cls" onClick={() => { toggleMenu() }}>
                                            <span className="x-btn-text blink" id="x-btn-text">x</span>
                                        </div>
                                        <span className="blink" id="browser-drag-tag">&nbsp;</span>
                                        <img src="/assets/denim.png" alt="denim texture" id="denim" />
                                        <div className="browser-title" id="browser-title"
                                            style={{ width: currentItem?.name?.length >= 19 || currentItem?.album_title?.length >= 19 ? '42.5%' : currentItem?.name?.length <= 10 || currentItem?.album_title?.length <= 10 ? '22%' : '35%' }}
                                        >
                                            <span style={{ display: 'flex', gap: 1 }}><img src={navValue !== null ? projects[navValue]?.icon_url : '/assets/Parallaxr-Distressed(White BG).png'} id="browser-favicon" />
                                                <span style={{ position: 'absolute', left: '1.5rem', top: '0.25rem' }}>{navValue < 3 ? currentItem?.name : navViews[navValue]}{navValue === null ? 'Gloaun' : ''}{navValue === 2 && currentItem?.album_title}</span></span>
                                            {/* project name */}
                                        </div>
                                    </div>
                                    <div className="browser-nav-bar">
                                        <div className="url-arrows">
                                            <span id="nav-left" className="nav-btn" onClick={() => {
                                                if (navValue === null) {
                                                    setNavValue(navViews?.length - 1)
                                                    setCurrentItem()
                                                }
                                                else if (navValue > 0) {
                                                    setNavValue(navValue - 1)
                                                    setCurrentItem()
                                                }
                                                else if (navValue === 0) {
                                                    setNavValue(null)
                                                    setCurrentItem()
                                                }
                                            }} style={{ cursor: 'pointer', zIndex: 999999 }}>←</span>

                                            <span id="nav-right" className="nav-btn" onClick={() => {
                                                if (navValue === null) {
                                                    setNavValue(0)
                                                    setCurrentItem()
                                                }
                                                else if (navValue < navViews?.length) {
                                                    setNavValue(navValue + 1)
                                                    setCurrentItem()
                                                }
                                                else {
                                                    setNavValue(null)
                                                    setCurrentItem()
                                                }
                                            }
                                            } style={{ cursor: 'pointer', zIndex: 9999999 }}>→</span>

                                            <span id="nav-refresh" className="nav-btn"> @ </span>
                                        </div>
                                        <div className="url-box">
                                            <div className="infoTrigger infoButton blink " id="infoButton" style={{ zIndex: 11 }} onClick={() => { toggleInfo() }}>
                                                <img src="/assets/infoButton.png" alt="info" className='hover-shake' />
                                                <div className="browser-info-alert hover-shake" id="browser-info-alert">click to view site info</div>
                                            </div>  <a id="browser-url" style={{ zIndex: 20 }} href={currentItem?.url ? currentItem?.url : navValue === null ? '#' : '#'} target='_blank'>
                                                {currentItem ? currentItem?.url : ' '}
                                            </a>
                                            <p id="browser-url">

                                                {navValue === null ? 'Gloaun' : ''}
                                                {navValue === 4 ? 'AJ@RexEntropy.com' : ''}
                                            </p>
                                        </div>
                                        {/* <div ç> */}
                                        <Tooltip title="More info">
                                            <MoreVertIcon onClick={() => { toggleInfo() }} sx={{ cursor: 'pointer', zIndex: 9999999 }} className='hover-shake' />
                                        </Tooltip>
                                        {/* </div> */}
                                    </div>
                                    {/* <div className="browser-body" style={{backgroundImage: `url("/assets/LT_BLUE_WHITE_STRIPE_BG.jpg")`,backgroundSize: "cover",backgroundRepeat:'no-repeat',}}> */}
                                    <div className="browser-body" style={{
                                        background: '#fff',
                                        backgroundImage: navValue === null && !blink ? `url("/assets/GLOAUN.png")` : navValue === null && blink ? `url("/assets/GLOAUN_Blink.png")` : `url("/assets/BodyBG2.png")`,
                                        // background:( navValue === 0 || navValue === 1)? '#fff':'',
                                        backgroundSize: navValue === null ? 'contain' : "cover", backgroundRepeat: 'no-repeat', backgroundPosition: 'center'
                                    }}>
                                        {/* <div class="browser-body-content"> </div> */}

                                        {navValue === 0 && <div className="browser-body-content"> <BrowserContent project={currentItem} view={'projects'} /></div>}
                                        {navValue === 1 && <div className="browser-body-content"> <BrowserContent project={currentItem} view={'projects'} /></div>}
                                        {navValue === 2 && <div className="browser-body-content"> <BrowserContent project={currentItem} view={'music'} /></div>}
                                        {/* {navValue === 3 && <div className="browser-body-content"> <ResumeComponent project={currentItem}/></div> } */}
                                        {/* {navValue === 4 && <div className="browser-body-content"> <ContactContent project={currentItem}/></div> } */}
                                        {/* {navValue === 3 &&   

               <Box component={'img'}
                     onMouseEnter={() => setBlink(true)}
                    onMouseLeave={() => setBlink(false)}
                    src={!blink?'/assets/Resume-2024-pg1.png':'/assets/Resume-2024-pg2.png'}
                        sx={{position:'relative',top:0,left:0,//width:'100%',
                        // backgroundImage: `url("/assets/Self-Portrait-Plain-NoGlasses.png")`,
                        // backgroundAttachment:'fixed',
                        // backgroundSize:'cover',
                    // // backgroundSize: "contain",
                        height:'100%',
                        width:'auto',
                        cursor: 'pointer'
                      
                    }}
                    >
                        
                    </Box>
                    } */}


                                    </div>
                                </div>
                            </div>)}
                            {/* <Box sx={{position: 'absolute',bottom:'0.5rem',left:'50%',transform:'translate(-50%,0)',backgroundColor:'rgba(255,255,255,0.3)',
          backdropFilter:'blur(5px)', height:'2rem', width:'70%',borderRadius:'5px',zIndex:99999, boxShadow:'0px 0px 10px 5px rgba( 0, 0, 0, 0.5)'}}>
        &nbsp;
       </Box> */}

                            {navValue === 3 && <ResumeComponent project={currentItem} isContact={isContact} toggleDrawer={setMenuState} var={menuState} />}
                            {infoOpen && <Box sx={{ height: '100px', fontSize: '0.85rem', width: '100%', zIndex: 9999 }}>
                                <DevToolsWindow project={currentItem} toggleInfo={toggleInfo} navValue={navValue} />
                            </Box>}
                        </Paper>
                        {/* <ListItem>{p.name}</ListItem> */}
                        {/* <Box sx={{ position:'relative', flex:1, color: term_green, backgroundColor: accent_color_1, fontSize:'0.75rem'}}>
               { arrayChunk(projects,3)?.map((row,i)=>{

                //   <div  key={i} className="row mx-auto">
                  return (
                    // <List key={i} className="row mx-auto">
                    <Box key={i}>
                        { row?.map((col, k) => (
                            <div key={k}>[{col?.name}]</div>
                            ))}
                    </Box>
                        // </List>
                        )
                //  </div>
                    })}
                    
           </Box> */}
                        <Box sx={{
                            position: 'relative', flex: 1, backgroundColor: vars.colors.accent_color_1,
                            fontSize: '0.75rem', minHeight: '275px', height: '30vh', maxWidth: '100%',
                            overflow: 'hidden', borderRadius: '5px 5px 0 0',
                            boxShadow: '0px -1px 10px 5px rgba(0, 0, 0, 0.2)',
                            // zIndex:50,
                        }}>

                            <div style={{
                                width: "100%", height: '1.25rem',
                                backgroundColor: vars.colors.term_header,
                                color: vars.colors.term_headerText,
                                borderRadius: '5px 5px 0px 0px',
                                textAlign: 'center',
                                alignItems: 'center',

                            }}>
                                <div className="term-button-cls" id="term-button-cls" onClick={() => { toggleMenu() }}><span id="x-btn-text">x</span></div>
                                <Typography sx={{ fontSize: '0.8rem', fontWeight: 'bold' }}>
                                    - Projects
                                </Typography>
                            </div>
                            <Typography sx={{ fontSize: '0.7rem', color: vars.colors.projectTitle, fontFamily: vars.fonts.term_title, marginLeft: '0.5rem' }}>ajphnx@ ~/Projects/{navValue === 3 ? navViews[navValue].split('/')[0] : navViews[navValue]} %
                                <span style={{ color: '#00FF00' }}>&nbsp; &nbsp;ls</span> </Typography>
                            <Box sx={{ margin: '0rem', marginTop: currentItem ? '-0.5rem' : '0', marginBottom: !currentItem ? '-0.5rem' : '0', fontFamily: vars.fonts.term_text, padding: '0.5rem' }}>
                                {(navValue === null) && <Box sx={{
                                    display: 'flex', justifyContent: 'space-evenly', textAlign: 'left', width: '100%',
                                    height: '1.25rem', whitespace: 'nowrap', textOverflow: 'ellipsis', margin: 'auto', marginTop: '-0.25rem',
                                    color: vars.colors.term_green, gap: 2
                                }}>

                                    {navViews?.map((v, i) => { return (<span style={{ marginTop: '0.5rem' }} className='term-link' key={i} onClick={() => setNavValue(navViews.indexOf(v))}>{v}</span>) })}
                                </Box>}
                                {(navValue === 3) && <Box sx={{
                                    display: 'flex', justifyContent: 'space-evenly', textAlign: 'left', width: '100%',
                                    height: '1.25rem', whitespace: 'nowrap', textOverflow: 'ellipsis', margin: 'auto', marginTop: '-0.25rem',
                                    color: vars.colors.term_green, gap: 2
                                }}>
                                    <span onClick={handleResumeToggle} style={{ cursor: 'pointer', color: '#00FF00' }} className='term-link'>
                                        {/* {isContact?'':<>&#8226;</>} */}
                                        Resume
                                    </span>
                                    <span onClick={handleContactToggle} style={{ cursor: 'pointer', color: '#00FF00' }} className='term-link'>
                                        {/* {isContact?<>&#8226;</>:''} */}
                                        Contact
                                    </span>

                                    {/* {navViews?.map((v,i)=>{ return (<span style={{ marginTop: '0.5rem' }} className='term-link' key={i} onClick={()=>setNavValue(navViews.indexOf(v))}>{v}</span>)  })} */}
                                </Box>}

                                {(navValue === 0 || navValue === 1) && arrayChunk(tagFilter(projects, navValue), width >= 500 ? 4 : 3)?.map((row, i) => (

                                    <Box key={i} sx={{
                                        display: 'flex', justifyContent: 'space-evenly', textAlign: 'left', width: '100%',
                                        // border:'1px solid red',
                                        height: '1.25rem', whitespace: 'nowrap', textOverflow: 'ellipsis', margin: 'auto', marginTop: '0rem', marginBottom: '-0.25rem',
                                        color: vars.colors.term_green, gap: 4
                                    }}>

                                        {row?.map((col, k) => {
                                            if (col?.name === 'Filler') {
                                                return (<span key={k}
                                                    // className='term-link'
                                                    style={{
                                                        textAlign: 'left', width: '160px', overflow: 'hidden',
                                                        whiteSpace: 'nowrap', textOverflow: 'ellipsis', cursor: 'pointer', fontSize: '0.7rem',
                                                        // border:'1px solid red'

                                                    }}
                                                // onClick={(e)=> handleItemClick(col) }
                                                // name={col?.name}
                                                >&nbsp;</span>)
                                            } else {

                                                return (
                                                    <span key={k} className='term-link'
                                                        style={{
                                                            textAlign: 'left', width: '160px', overflow: 'hidden',
                                                            whiteSpace: 'nowrap', textOverflow: 'ellipsis', cursor: 'pointer', fontSize: '0.7rem'
                                                        }}
                                                        onClick={(e) => handleItemClick(col)}
                                                        name={col?.name}
                                                    >{col?.name}</span>
                                                )
                                            }
                                        })}

                                    </Box>
                                    // </List>
                                )
                                    //  </div>
                                )}



                                {/* {(navValue ===  2) && arrayChunk(tagFilter(music,navValue),2)?.map((row,i)=>( */}

                                {navValue === 2 && <Box sx={{
                                    display: 'flex', justifyContent: 'space-evenly', textAlign: 'left', width: '100%',
                                    // border:'1px solid red',
                                    height: '1.25rem', whitespace: 'nowrap', textOverflow: 'ellipsis', margin: 'auto', marginTop: '0rem', marginBottom: '-0.25rem',
                                    color: vars.colors.term_green, gap: 4
                                }}>

                                    {music?.map((a, k) => {

                                        return (
                                            <span key={k} className='term-link'
                                                style={{
                                                    textAlign: 'left', width: '160px', overflow: 'hidden',
                                                    whiteSpace: 'nowrap', textOverflow: 'ellipsis', cursor: 'pointer', fontSize: '0.7rem'
                                                }}
                                                onClick={(e) => handleItemClick(a)}
                                                name={a?.album_title}
                                            >{a?.album_title}</span>
                                        )

                                    }
                                    )}

                                </Box>}

                            </Box>
                            {(!currentItem && navValue !== 4) && !linkClick && tagFilter((navValue === 0 || navValue === 1) ? projects : music, navValue) && <Typography className='blink typing-med'
                                sx={{
                                    display: 'flex', color: '#00FF00', fontSize: '2rem', justifyContent: 'center',
                                    alignSelf: 'center', margin: 'auto', position: 'relative',
                                    fontFamily: 'Gilroy, sans-serif', fontWeight: 'bold', marginTop: navValue === null ? '1.5rem' : '0.25rem'
                                }}

                            >
                                {navValue === null && <div className='just-moving-alt'>&#8681;</div>}
                                <div className='just-moving'>&#8682;</div>&nbsp;Click to view&nbsp;<div className='just-moving'>&#8682;</div>
                                {navValue === null && <div className='just-moving-alt'>&#8681;</div>}
                            </Typography>}

                            {currentItem &&
                                <div style={{ display: 'flex', flexDirection: 'column', gap: '0.125rem', marginTop: '-0.25rem' }}>
                                    <span style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                        <span style={{
                                            fontSize: '0.75rem', color: vars.colors.projectTitle,
                                            fontFamily: vars.fonts.term_title, marginLeft: '0.5rem', display: 'flex'
                                        }}>
                                            ajphnx@ ~/Projects/{navViews[navValue]} %
                                        </span>
                                        <span style={{
                                            fontSize: '0.65rem', color: vars.colors.term_green,
                                            fontFamily: vars.fonts.term_text, marginLeft: '0.5rem'
                                        }}>
                                            cd {currentItem?.name}
                                        </span>
                                    </span>
                                    <span style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                        <span style={{
                                            fontSize: '0.7rem', color: vars.colors.projectTitle,
                                            fontFamily: vars.fonts.term_title, marginLeft: '0.5rem', display: 'flex'
                                        }}>
                                            ajphnx@ ~/{currentItem?.name?.length < 13 || currentItem?.album_title?.length < 13 ? 'Projects' : '..'}/{navViews[navValue]}/{currentItem?.name}/ %
                                        </span>
                                        <span style={{
                                            fontSize: '0.6rem', color: vars.colors.term_green,
                                            fontFamily: vars.fonts.term_text, marginLeft: '0.5rem'
                                        }}>
                                            npm install
                                        </span>
                                    </span>
                                    <span style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                        <span style={{
                                            fontSize: '0.7rem', color: vars.colors.projectTitle,
                                            fontFamily: vars.fonts.term_title, marginLeft: '0.5rem', display: 'flex'
                                        }}>
                                            ajphnx@ ~/{currentItem?.name?.length < 13 || currentItem?.album_title?.length < 13 ? 'Projects' : '..'}/{navViews[navValue]}/{currentItem?.name}/ %
                                        </span>
                                        <span style={{
                                            fontSize: '0.6rem', color: vars.colors.term_green,
                                            fontFamily: vars.fonts.term_text, marginLeft: '0.5rem'
                                        }}
                                            className='typing'
                                        >
                                            npm start&nbsp;
                                        </span>
                                    </span>
                                </div>
                            }

                            {(navValue === 3 && linkClick) &&
                                <div style={{ display: 'flex', flexDirection: 'column', gap: '0.125rem', marginTop: '-0.25rem' }}>
                                    <span style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                        <span style={{
                                            fontSize: '0.75rem', color: vars.colors.projectTitle,
                                            fontFamily: vars.fonts.term_title, marginLeft: '0.5rem', display: 'flex'
                                        }}>
                                            ajphnx@ ~/Projects/{navViews[navValue].split('/')[0]} %
                                        </span>
                                        <span style={{
                                            fontSize: '0.65rem', color: vars.colors.term_green,
                                            fontFamily: vars.fonts.term_text, marginLeft: '0.5rem'
                                        }}>
                                            cd {isContact ? 'Contact' : 'Resume'}
                                        </span>
                                    </span>
                                    <span style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                        <span style={{
                                            fontSize: '0.7rem', color: vars.colors.projectTitle,
                                            fontFamily: vars.fonts.term_title, marginLeft: '0.5rem', display: 'flex'
                                        }}>
                                            ajphnx@ ~/{currentItem?.name?.length < 13 || currentItem?.album_title?.length < 13 ? 'Projects' : '..'}/{navViews[navValue].split('/')[0]}/{isContact ? 'Contact' : 'Resume'} %
                                        </span>
                                        <span style={{
                                            fontSize: '0.6rem', color: vars.colors.term_green,
                                            fontFamily: vars.fonts.term_text, marginLeft: '0.5rem'
                                        }}>
                                            npm install
                                        </span>
                                    </span>
                                    <span style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                        <span style={{
                                            fontSize: '0.7rem', color: vars.colors.projectTitle,
                                            fontFamily: vars.fonts.term_title, marginLeft: '0.5rem', display: 'flex'
                                        }}>
                                            ajphnx@ ~/{currentItem?.name?.length < 13 || currentItem?.album_title?.length < 13 ? 'Projects' : '..'}/{navViews[navValue].split('/')[0]}/{isContact ? 'Contact' : 'Resume'} %
                                        </span>
                                        <span style={{
                                            fontSize: '0.6rem', color: vars.colors.term_green,
                                            fontFamily: vars.fonts.term_text, marginLeft: '0.5rem'
                                        }}
                                            className='typing'
                                        >
                                            npm start&nbsp;
                                        </span>
                                    </span>
                                </div>
                            }

                        </Box>

                    </Box>
                    {/* )} */}
                    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
                        <BottomNavigation
                            showLabels
                            value={navValue}
                            onChange={(event, newnavValue) => {
                                setCurrentItem()
                                setNavValue(newnavValue);
                            }}
                        >

                            <BottomNavigationAction label="Design" icon={<DesignServicesIcon />} />
                            <BottomNavigationAction label="Dev" icon={<DeveloperModeIcon />} />
                            <BottomNavigationAction label="Music" icon={<AlbumIcon />} />
                            <BottomNavigationAction label="Info/Contact" icon={<ContactPageIcon />} />
                            {/* <BottomNavigationAction label="Info/Contact" icon={<GradingIcon />} /> */}
                            {/* <BottomNavigationAction label="Contact" icon={<ContactPageIcon />} /> */}
                        </BottomNavigation>
                    </Paper>

                </SwipeableDrawer>
                {/* </React.Fragment> */}
            </Container>
            {/* {(navValue === 3 && menuState && magnifiedArea?.isViewAble) && <div style={{
                position:'absolute',left:0,top:0, 
                width:'100%', 
                maxWidth:'100vw', 
                height:'300px',
                zIndex:9999999999,
                border:'2x solid black', 
                borderRadius:'20px', 
                overflow: 'hidden'
                }}>
    {/* <div style={{
                position:'absolute',left:0,top:0, width:'100%', height:'100%',
                // border:'1px solid red', 
                //borderRadius:'20px', overflow: 'hidden'
                }}>  */}
            {/* <MagView magnification={3}/> */}
            {/* </div> */}
            {/* </div>} */}
            {isFullView && <FullViewComponent />}
        </div >

    )
}

export default Home