import { Box, Container, Stack } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';

const TwineStory = ({ isVisible, setShowTwineStory, showWarning,
  onStoryComplete, setIsPaused, setIsGameStart, isGameStart, setIsActivated,
  isActivated, setFrameRef, setCurrentPassage,
  currentPassage, setIsGameEnd }) => {
  const iframeRef = useRef(null);
  const [blink, setBlink] = useState(false)


  setFrameRef(iframeRef)
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const [isSleep, setIsSleep] = useState(true)

  const blinkFunc = async () => {
    let blinkTimes = Math.floor(Math.random() * 1) + 2
    setBlink(false);
    for (let i = 0; i < blinkTimes; i++) {

      setBlink(true);
      await sleep(Math.floor(Math.random() * 1000) + 500)
      setBlink(false);
    }
  }

  useEffect(() => {
    if (isVisible && iframeRef.current) {
      navigateToPassage(currentPassage);
      iframeRef.current.src = `/assets/RexEntropyGameIntro.html#${currentPassage}`;
      console.log('[iframeRef.current.src ]:,', iframeRef.current.src);
      setIframeLoaded(true);
    }

  }, [iframeLoaded, isVisible, currentPassage]);

  useEffect(() => {
    if (iframeLoaded && iframeRef.current) {
      iframeRef.current.onload = () => {
        console.log('Iframe loaded, sending navigation message');
        setTimeout(async () => {
          await navigateToPassage(currentPassage);
        }, 0);
      };
    }
  }, [iframeRef.current, iframeLoaded, currentPassage]);


  // useEffect(() => {

  //         navigateToPassage(currentPassage);

  // }, [currentPassage]);



  const navigateToPassage = async (passageName) => {
    try {

      if (iframeRef.current && iframeRef.current.contentWindow) {
        console.log('Sending message to navigate to:', passageName);
        // console.log('Sending message [content window]:', iframeRef.current.contentWindow);
        iframeRef.current.contentWindow.postMessage({
          type: 'navigateToPassage',
          passageName: passageName
        }, '*');
      }
    } catch (err) { console.log(err) }
  };


  // useEffect(() => {
  //   try{

  //     const iframeWindow = iframeRef?.current?.contentWindow;
  //       //if( !iframeWindow?.State?.variables?.hideStory ){
  //       // iframeWindow.State.variables.hideStory = false;
  //     //   console.log('[Twine Story] hideStory:', iframeWindow?.State?.variables?.hideStory)
  //     // }
  //       // if (isVisible ) {
  //       if (isVisible && iframeRef.current) {
  //           // Load the Twine story
  //             // iframeRef.current.src = `/assets/RexEntropyGameIntro.html#SystemWarning`;

  //             iframeRef.current.src = `/assets/RexEntropyGameIntro.html`;
  //           console.log('[Twine Story] src:', iframeRef.current.src)
  //           console.log('[Twine Story] currentPassage:', currentPassage)
  //            if (iframeRef.current) {
  //           setTimeout(() => {
  //           iframeRef.current.onload = () => {
  //             navigateToPassage(currentPassage);
  //           }
  //         },500);
  //       }

  //     }
  //   }catch(err){console.log(err)}
  // }, [ currentPassage]);

  //   useEffect(() => {
  //   if (iframeRef.current) {
  //     iframeRef.current.onload = () => {
  //     // if(currentPassage !=='Intro'){
  //       navigateToPassage(currentPassage);
  //     }
  //     };
  //   // }
  // }, []);

  //----Listen to messages from Twine
  window.addEventListener('message', function (event) {

    if (event.data === 'twine-story-complete') {
      // Handle the completion of the Twine story
      // For example, hide the Twine HUD and resume gameplay
      setShowTwineStory(false);
      setIsPaused(false);
    }
    if (event.data === 'hide-story') {
      // Handle the completion of the Twine story
      // For example, hide the Twine HUD and resume gameplay
      console.log('[Twine Story] hide-story:', event.data)
      setShowTwineStory(false);
      setIsPaused(false);
    }
    if (event.data === 'show-story') {
      // Handle the completion of the Twine story
      // For example, hide the Twine HUD and resume gameplay
      //  console.log('[Twine Story] show-story:', event.data)
      setShowTwineStory(true);
      setIsPaused(true);
    }
    if (event.data === 'close-game') {
      // Handle the completion of the Twine story
      // For example, hide the Twine HUD and resume gameplay
      //  console.log('[Twine Story] show-story:', event.data)
      setShowTwineStory(false);
      setIsPaused(true);
      setIsGameEnd(true)
      window.close()
    }
    if (event.data === 'end-game') {
      // Handle the completion of the Twine story
      // For example, hide the Twine HUD and resume gameplay
      //  console.log('[Twine Story] show-story:', event.data)
      setShowTwineStory(false);
      setIsPaused(true);
      setIsGameEnd(true)

    }
    if (event.data === 'basic-ending') {
      try {

        setCurrentPassage('BasicConclusion')
        const event = new CustomEvent('BasicConclusion', { detail: { isActivated: false } });
        document.dispatchEvent(event);
        console.log('----------[basic-ending listener]---------')
        //  setShowTwineStory(false)
        //  setIsPaused(false)
      } catch (err) { console.log('[basic-ending listener]', err) }


    }
    if (event.data === 'activate') {
      // Handle the completion of the Twine story
      // For example, hide the Twine HUD and resume gameplay
      //  console.log('[Twine Story] show-story:', event.data)
      //  console.log('[Twine Story] show-story:', event.data)
      // setCurrentPassage(true);

      setShowTwineStory(false);
      setIsPaused(false);
    }
  });

  useEffect(() => {

    const handleHideStory = () => {
      const iframeWindow = iframeRef.current.contentWindow;
      if (iframeWindow && iframeWindow.State.variables.hideStory) {
        setShowTwineStory(true);
        setIsPaused(false);
        if (!isGameStart) {
          setIsGameStart(true)
        }
      }
      if (iframeWindow && !iframeWindow.State.variables.hideStory) {
        setShowTwineStory(true);
        setIsPaused(true);
        if (!isGameStart) {
          setIsGameStart(true)
        }
      }
    };

    if (iframeRef.current) {
      iframeRef.current.addEventListener('load', () => {
        iframeRef.current.contentWindow.addEventListener('hide-story', handleHideStory);
        iframeRef.current.contentWindow.addEventListener('show-story', handleHideStory);
      });
    }

    return () => {
      if (iframeRef.current) {
        iframeRef.current.contentWindow.removeEventListener('hide-story', handleHideStory);
        iframeRef.current.contentWindow.removeEventListener('show-story', handleHideStory);
      }
    };
  }, [isVisible]);
  const sleep = (time) => {
    return new Promise(resolve => {
      setTimeout(resolve, time)
      // setAnimationFrame instread for event loop purposes
    })
  }

  const skipToGame = () => {

    setShowTwineStory(false);
    setIsPaused(false);
  }
  useEffect(() => {

    const wakeMedMan = () => {
      if (isSleep) {

        setIsSleep(false)
      }
    }
    sleep(5000)
    wakeMedMan()
  }, isSleep)

  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
  useEffect(() => {
    const blinkInc = !blink ? 1 : getRandomInt(1, 2);
    if (blink) {
      sleep(1000)
    }
    const intervalId = setInterval(() => {
      blinkFunc()
    }, Math.floor(Math.random() * 1000) + (5000 * blinkInc)); // Random interval between 1 to 6 seconds

    return () => {

      clearInterval(intervalId);
    };
  }, []);


  return (
    <Container id='twine-story'
      style={{ width: '100%', height: '100%', position: 'fixed', left: 0, top: '10%', zIndex: 9, overflow: 'hidden' }}
    >
      <Stack id='twine-llxr' style={{
        cursor: 'pointer', position: 'fixed',
        top: '10%', right: '10%', width: '100px', height: 'auto',
        justifyContent: 'center',
        zIndex: 9999999
      }}
        onClick={() => {
          skipToGame()
        }}
      >
        <img src={isSleep ? "/assets/MedMan_Flat.png" : blink ? "/assets/MedMan_UpBlink.png" : "/assets/MedMan_UpFace.png"} width='100%' height='auto' />
        <Stack style={{
          position: 'relative',
          background: '#000', color: '#00FF00', padding: '0.25rem ', marginTop: '0.5rem',
          borderRadius: '5px', width: 'fit-content',
          height: '4rem',
          border: '2px bevel #858585',
          boxShadow: '0px 0px 5px 2px rgba(0 ,255, 0, 0.3),inset 0px 0px 3px 1px rgba(0 ,255, 0, 0.2)',
          alignItems: 'center',
          alignContent: 'center',
        }}>
          <Box sx={{
            // position: 'absolute', top: '0', left: '0',
            hieght: '0.75rem', width: '100%',
            background: '#848484', fontSize: '0.75rem',
            justifyContet: 'center', color: '#000', fontWeight: 'bold'
          }}>
            skip?
          </Box>
          <Box sx={{ margin: 'auto' }}>

            Skip to game
          </Box>
        </Stack>
      </Stack>
      <iframe
        ref={iframeRef}
        style={{ width: '100%', height: '100%', border: 'none' }}
        onLoad={() => {
          // Add event listener to detect when the story is complete
          iframeRef.current.contentWindow.addEventListener('twine-story-complete', onStoryComplete);
          iframeRef.current.contentWindow.addEventListener('hide-story', () => {
            setShowTwineStory(false)
          });
          iframeRef.current.contentWindow.addEventListener('show-story', () => {
            console.log('------Twine story event test--------')
            setShowTwineStory(true)

          });
        }}
      />
    </Container>




  );
};
export default TwineStory 